export default  {
    palette: {
      primary: {
        main: "#171616"
      },
      secondary: {
        main: "#EAD2A8"
      },
    },
    typography: {
        button: {
          textTransform: 'none',
          fontWeight:'bold'
        }
      },
      multilineColor:{
        color:'white'
    }
  }