import React, { Component, Link } from 'react'

import '../css/home.css'
import theme from '../utils/theme';
import { createTheme, ThemeProvider } from  "@mui/material/styles";

import { Button, Fade } from '@mui/material';


const themeProvider = createTheme(theme);


export class home extends Component {
  render() {
    return (
        <div>
        <ThemeProvider theme={themeProvider}>
            <div className="home-container">
            <Fade in={true} timeout={2000}>
                <h1>The King's List</h1>
                </Fade>
                <Fade in={true} timeout={2000}>
            <div className='home-link-list'>

                <Button
                component="a"
                href='https://amzn.to/3SJXFrf'
                target="_blank">
                <div className='home-link-item'>
                    <div className='link-image'>
                    <a href="https://www.amazon.com/Atmamun-achieving-Himalayan-Swamis-freedom/dp/1532762720?crid=1NGP6O827Z7N2&keywords=kapil+gupta&qid=1667085459&qu=eyJxc2MiOiIzLjA1IiwicXNhIjoiMi41NCIsInFzcCI6IjIuNDYifQ%3D%3D&sprefix=kapil+gupta%2Caps%2C77&sr=8-2&linkCode=li2&tag=kingbearer-20&linkId=af4beb8f31e1089c105009169af83da8&language=en_US&ref_=as_li_ss_il" target="_blank">
                        <img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=1532762720&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=kingbearer-20&language=en_US"/>
                    </a>
                    </div>
                    <div className='link-text'>
                        <h2>Atmamun</h2>
                        <h3>Kapil Gupta MD</h3>
                    </div>
                </div>
                </Button>

                <Button
                component="a"
                href='https://amzn.to/3SPtlLV'
                target="_blank">
                <div className='home-link-item'>
                    <div className='link-image'>
                    <a href="https://www.amazon.com/Masters-Secret-Whispers-those-living/dp/1975841689?keywords=kapil+gupta&qid=1667085541&qu=eyJxc2MiOiIzLjA1IiwicXNhIjoiMi41NCIsInFzcCI6IjIuNDYifQ%3D%3D&sr=8-3&linkCode=li2&tag=kingbearer-20&linkId=5e448c35d54e326236a70e48349d7000&language=en_US&ref_=as_li_ss_il" target="_blank">
                        <img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=1975841689&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=kingbearer-20&language=en_US" />
                        </a>
                    </div>
                    <div className='link-text'>
                        <h2>A Master's Secret Whispers</h2>
                        <h3>Kapil Gupta MD</h3>
                    </div>
                </div>
                </Button>

                <Button
                component="a"
                href='https://amzn.to/3gVfruA'
                target="_blank">
                <div className='home-link-item'>
                    <div className='link-image'>
                    <a href="https://www.amazon.com/Direct-Truth-Uncompromising-non-prescriptive-questions/dp/1724334417?keywords=kapil+gupta&qid=1667085580&qu=eyJxc2MiOiIzLjA1IiwicXNhIjoiMi41NCIsInFzcCI6IjIuNDYifQ%3D%3D&sr=8-6&linkCode=li2&tag=kingbearer-20&linkId=f0a988b8e517750a5b734f4e0f84c8ac&language=en_US&ref_=as_li_ss_il" target="_blank">
                        <img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=1724334417&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=kingbearer-20&language=en_US" />
                    </a>

                    </div>
                    <div className='link-text'>
                        <h2>Direct Truth</h2>
                        <h3>Kapil Gupta MD</h3>
                    </div>
                </div>
                </Button>

                <Button
                component="a"
                href='https://amzn.to/3DKRyPq'
                target="_blank">
                <div className='home-link-item'>
                    <div className='link-image'>
                    <a href="https://www.amazon.com/Meditations-New-Translation-Marcus-Aurelius/dp/0812968255?crid=2Q4YX6M30V7LC&keywords=meditations&qid=1667085623&qu=eyJxc2MiOiIzLjQzIiwicXNhIjoiMi43MiIsInFzcCI6IjIuNTgifQ%3D%3D&sprefix=meditations%2Caps%2C98&sr=8-1&linkCode=li2&tag=kingbearer-20&linkId=c840f7ac370c283b15c5077e3585668a&language=en_US&ref_=as_li_ss_il" target="_blank">
<img border="0" src='//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=0812968255&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=kingbearer-20&language=en_US' />
</a>
                    </div>
                    <div className='link-text'>
                        <h2>Meditations</h2>
                        <h3>Marcus Aurelius</h3>
                    </div>
                </div>
                </Button>

                <Button
                component="a"
                href='https://amzn.to/3sIKBrJ'
                target="_blank">
                <div className='home-link-item'>
                    <div className='link-image'>
                    <a href="https://www.amazon.com/Way-Love-Meditations-Anthony-Classics/dp/038524939X?crid=2P0KWLE8YE9V5&keywords=way+to+love&qid=1667085674&qu=eyJxc2MiOiIxLjcxIiwicXNhIjoiMS4wNCIsInFzcCI6IjEuMjYifQ%3D%3D&sprefix=way+to+love%2Caps%2C117&sr=8-1&linkCode=li2&tag=kingbearer-20&linkId=cf893955cb8c7fec5677e9ad37602fa2&language=en_US&ref_=as_li_ss_il" target="_blank">
<img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=038524939X&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=kingbearer-20&language=en_US" />
</a>
                    </div>
                    <div className='link-text'>
                        <h2>The Way To Love</h2>
                        <h3>Anthony de Mello</h3>
                    </div>
                </div>
                </Button>
              


                </div>
                </Fade>
                </div>
                </ThemeProvider>
                </div>
                

    )
  }
}

export default home