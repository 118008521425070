import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';

import Home from './pages/home'; 

import theme from './utils/theme';
import { styled, createTheme, ThemeProvider } from  "@mui/material/styles";

const themeProvider = createTheme(theme);



function App() {
  return (
    <ThemeProvider theme={themeProvider}>
    <Router>
 <Routes>
   <Route exact path ="/" element={<Home/>}/>
   </Routes>
   </Router>
   </ThemeProvider>
  );
}

export default App;
